// Theme variables, must be included before the libraries to allow overriding defaults
@tailwind base;
@tailwind components;
@tailwind utilities;

// 3rd party libraries

// Theme customization
@import './assets/scss/app.scss';


.ant-drawer-body .ant-row {
    display: block;
}